const initState = () => ({
  invoices: [],
  options: {},
  page: 1,
  totalItems: 0,
})

export const state = initState

export const getters = {
}

export const mutations = {
  setInvoices(state, {invoices}) {
    state.invoices = invoices
  },
  setOptions(state, {options}) {
    state.options = options
  },
  setTotalItems(state, {totalInvoices}) {
    state.totalItems = totalInvoices
  },
  updateInvoiceItemAppliedAmountWithBalance(state, item) {
    if(state.invoices.items) {
      state.invoices.items.map(invoiceItem => {
        if(invoiceItem.id === item.id)
          invoiceItem.appliedAmount = item.balance
      })
    }
  }
}

export const actions = {
  async getInvoices({commit, state}, {filters}) {
    const {page, itemsPerPage} = state.options
    let url = `/invoices?pageSize=${itemsPerPage}&pageNo=${page}&sortAsc=false${filters}`
    const result = await this.$axios.$get(url)
    commit('setInvoices', {invoices: result.items})
    commit('setTotalItems', {totalInvoices: result.pagingData.itemCount})
  },
  generateInvoicePDFReport({commit}, invoiceId) {
    this.$axios.get(`/invoices/generateInvoicePDFReport/${invoiceId}`, {responseType: 'blob'}).then(response => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
      window.open(url, "_blank");
    })
  },
  generateInvoicePDFReportForMultipleInvoices({commit}, invoiceIds) {
    this.$axios.post(`/invoices/printMultipleInvoices`, invoiceIds, {responseType: 'blob'}).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
      window.open(url, "_blank");
    })
  }
}
