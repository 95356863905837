export default ({store, $auth, route, redirect}) => {
  // Check if user is connected first
  if (!$auth.$state.loggedIn) return redirect('/')

  // Get authorizations for matched routes (with children routes too)
  const authorizationLevels = route.meta.map((meta) => {
    if (meta.auth && typeof meta.auth.authority !== 'undefined')
      return meta.auth.authority
    return 0
  })

  if (
    !$auth.user.permissions ||
    !$auth.user.permissions.find((x) =>
      authorizationLevels.some((a) => a === x)
    )
  ) {
    return redirect('/backoffice/authorization-error')
  }
}
